import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIDataTable from "mui-datatables";

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orgs: this.props.orgs,
    };
  }

  componentDidMount() {
    // DISABLING AUTO REFRESH BECAUSE NO ONE CHECKS IT ACTIVELY
    // setInterval(() => $.ajax({
    //   url: '/golden_eye/get_receiver_status.json',
    //   type: 'GET'
    // }).done(function (data) {
    //   this.setState({orgs: data.orgs})
    // }.bind(this)), 30000);
  }

  getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            paper: {
              backgroundColor: "#25282c",
              boxShadow: "none !important",
            },
          },
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              fontSize: "24px !important",
              color: "#f2f2f2 !important",
            },
          },
        },
        MuiTable: {
          styleOverrides: {
            root: {
              border: "none",
            },
          },
        },
        MUIDataTablePagination: {
          styleOverrides: {
            root: {
              position: "fixed",
              bottom: "0px",
              width: "100vw",
              backgroundColor: "#51565E",
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            select: {
              paddingRight: "40px !important",
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "capitalize",
              fontSize: "16px",
              fontWeight: "bold",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            sortActive: {
              color: "#f2f2f2",
            },
          },
        },
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              background: "transparent !important",
              fontSize: "40px !important",
              fill: "#f2f2f2 !important",
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              fontSize: "36px !important",
              color: "#f2f2f2 !important",
              "&:before": {
                borderBottom: "1px solid #f2f2f2",
              },
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            underline: {
              "&:after": {
                borderBottom: "1px solid #f2f2f2",
              },
              "&:before": {
                borderBottom: "1px solid #f2f2f2",
              },
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              backgroundColor: "#51565E",
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              fontSize: "24px",
              color: "#f2f2f2",
            },
          },
        },
        MUIDataTableFilter: {
          styleOverrides: {
            root: {
              backgroundColor: "#51565E",
            },
            title: {
              fontSize: "16px",
              color: "#f2f2f2",
            },
            resetLink: {
              fontSize: "16px",
              color: "#6CA9FF",
            },
          },
        },
        MuiFormLabel: {
          styleOverrides: {
            root: {
              fontSize: "24px",
              color: "#f2f2f2 !important",
            },
          },
        },
        MuiGridListTile: {
          styleOverrides: {
            root: {
              width: "100% !important",
            },
          },
        },
        MuiTablePagination: {
          styleOverrides: {
            root: {
              width: "calc(100vw - 210px) !important",
            },
          },
        },
        MuiTableSortLabel: {
          styleOverrides: {
            root: {
              color: "#f2f2f2",
              fontSize: 30,
              background: "transparent",
              "&:hover": {
                color: "#f2f2f2",
                fontSize: 30,
                background: "transparent",
                "&& $icon": {
                  opacity: 1,
                  fontSize: 30,
                  color: "#f2f2f2",
                  background: "transparent",
                },
              },
              "&$active": {
                color: "#f2f2f2",
                fontSize: 30,
                background: "transparent",
                "&& $icon": {
                  opacity: 1,
                  fontSize: 30,
                  background: "transparent",
                  color: "#f2f2f2",
                },
              },
            },
          },
        },
        MUIDataTableBody: {
          styleOverrides: {
            emptyTitle: {
              fontSize: "24px",
              fontFamily: "Roboto",
              fontWeight: "bold",
            },
          },
        },
      },
    });

  render() {
    const filter_col_opt = {
      filter: true,
      display: true,
      sort: true,
      searchable: true,
    };
    const no_filter_col_opt = {
      filter: false,
      display: true,
      sort: true,
      searchable: true,
    };
    const hidden_column = {
      filter: false,
      display: false,
      sort: false,
      searchable: false,
    };
    const table_cols = [
      {
        name: "screener_organization_id",
        label: "Screener Organization ID",
        options: hidden_column,
      },
      {
        name: "receiver_organization_id",
        label: "Receiver Organization ID",
        options: hidden_column,
      },
      {
        name: "screener_organization_name",
        label: "Screener Organization Name",
        options: {
          filter: true,
          display: true,
          sort: true,
          searchable: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <a
                href={`/golden_eye/organization_information/${tableMeta["rowData"][0]}`}
              >
                {value}
              </a>
            );
          },
        },
      },
      {
        name: "receiver_organization_name",
        label: "Receiver Organization Name",
        options: {
          filter: true,
          display: true,
          sort: true,
          searchable: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <a
                href={`/golden_eye/organization_information/${tableMeta["rowData"][1]}`}
              >
                {value}
              </a>
            );
          },
        },
      },
      {
        name: "receiver_type",
        label: "Receiver Organization Type",
        options: filter_col_opt,
      },
      {
        name: "receiver_status",
        label: "Receiver Status",
        options: {
          filter: true,
          display: true,
          sort: true,
          searchable: true,
          setCellProps: (cellValue, rowIndex, columnIndex) => {
            var color = "#f2f2f2";
            if (cellValue === "Online") {
              color = "#1baf16";
            } else if (cellValue === "Offline") {
              color = "#ff3d3d";
            }
            return {
              style: {
                color: color,
              },
            };
          },
        },
      },
      {
        name: "software_version",
        label: "Software Version",
        options: filter_col_opt,
      },
      {
        name: "last_act_date",
        label: "Last Activity Date",
        options: no_filter_col_opt,
      },
      {
        name: "is_test_organization",
        label: "Is Receiver Test Organization",
        options: filter_col_opt,
      },
      // {
      //   name: "last_api_date",
      //   label: "Last API Call Date",
      //   options: no_filter_col_opt
      // },
    ];

    const table_options = {
      filter: true,
      filterType: "dropdown",
      search: true,
      print: false,
      download: false,
      viewColumns: false,
      selectableRows: "none",
      rowHover: false,
      rowsPerPage: 15,
      rowsPerPageOptions: [10, 15, 50],
      responsive: "vertical",
      setTableProps: () => {
        return {
          className: "ge-better-reports-table",
        };
      },
      setRowProps: (row) => {
        return {
          className: "ge-report-rows",
        };
      },
      textLabels: {
        body: {
          noMatch: "No reports matching search",
          toolTip: "",
        },
      },
    };
    return (
      <div className="main-content">
        <div id="record-editor-container" className="container-fluid">
          <div className="row-fluid">
            <div id="record-editor-header">
              <h1>RECEIVER STATUS</h1>
            </div>
            <div>
              <ThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable
                  data={this.state.orgs}
                  columns={table_cols}
                  options={table_options}
                />
              </ThemeProvider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
