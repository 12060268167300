import "isomorphic-fetch"
import React from 'react'
import MUIDataTable from "mui-datatables";
import WarningIcon from '@mui/icons-material/Warning';
import Button from 'react-bootstrap/Button';
import {createTheme, ThemeProvider} from '@mui/material/styles';

import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Pagination from '@mui/material/Pagination';


class Index extends React.Component {
  constructor(props) {
    super(props);

    let record_reports_columns = [
      {
        name: "incompleteCall",
        label: "Flag",
        options: {
          filter: true,
          sort: true,
          searchable: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Flag
                flagged={value}
              />
            );
          },
          setCellProps: (cellValue, rowIndex, columnIndex) => {
            return {
              style: {
                paddingRight: '25px',
                paddingLeft: '15px'
              }
            };
          },
          setCellHeaderProps: (cellValue, rowIndex, columnIndex) => {
            return {
              style: {
                paddingRight: '25px',
                paddingLeft: '15px'
              }
            };
          }
        }
      },
      {
        name: "incidentNumber",
        label: "Incident Number",
        options: {
          filter: false,
          sort: true,
          searchable: true
        }
      },
    ];
    if (props.is_plum_coulee) {
      record_reports_columns.push(      {
        name: "healthimID",
        label: "HealthIM ID",
        options: {
          filter: false,
          sort: true,
          searchable: true
        }
      })
    }

    const afterIdCols = [
      {
        name: "badgeNumber",
        label: "Badge Number",
        options: {
          filter: true,
          sort: true,
          display: 'false',
          searchable: true
        }
      },
      {
        name: "responderName",
        label: "Officer (Badge)",
        options: {
          filter: true,
          sort: true,
          searchable: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            var badge_number_col = tableMeta["columnIndex"] - 1;
            var badgeNumber = tableMeta["rowData"][badge_number_col];
            if (badgeNumber !== undefined && badgeNumber !== "") {
              badgeNumber = " (" + badgeNumber + ")"
            } else {
              badgeNumber = ""
            }
            return value + badgeNumber;
          }
        }
      },
      {
        name: "created_at",
        label: "Submission Time",
        options: {
          filter: false,
          sort: true,
          searchable: true,
        }

      },
      {
        name: "archived",
        label: "Archived",
        options: {
          filter: false,
          sort: false,
          display: 'false',
          searchable: false,
        }
      },
      {
        name: "id",
        label: " ",
        options: {
          filter: false,
          sort: false,
          searchable: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            var archive_col = tableMeta["columnIndex"] - 1;
            return (
              <ViewOrUnarchiveButton
                report_id={value}
                archived={tableMeta["rowData"][archive_col]}
                archiveReport={this.archiveReport}
              />
            );
          },
          setCellProps: (cellValue, rowIndex, columnIndex) => {
            return {
              style: {
                paddingRight: '10px'
              }
            };
          },
          setCellHeaderProps: (cellValue, rowIndex, columnIndex) => {
            return {
              style: {
                paddingRight: '10px'
              }
            };
          }
        }
      }
    ];
    record_reports_columns = record_reports_columns.concat(afterIdCols);

    this.state = {
      search_value: '',
      record_reports: props.record_reports,
      total: props.total,
      is_plum_coulee: props.is_plum_coulee,
      sortOrder: {name: "created_at", direction: "desc"},
      record_reports_columns: record_reports_columns,
      searching: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.searchFocusIn = this.searchFocusIn.bind(this);
    this.searchFocusOut = this.searchFocusOut.bind(this);
  }

  handleChange(event) {
    this.setState({
      search_value: event.target.value
    });
    
    if(this.state.searchTimer) {
      clearTimeout(this.state.searchTimer)
    }
    var timeout = setTimeout(function(){this.getListData(0, this.state.search_value, this.state.sortOrder)}.bind(this), 1000);
    
    this.setState({
      searchTimer: timeout
    })
  }

  handleClear() {
    this.setState({search_value: ""});
    this.getListData(0, "", this.state.sortOrder)
  }

  searchFocusIn() {
    this.setState({searching: true});
  };

  searchFocusOut() {
    this.setState({searching: false});
  };

  getMuiTheme = () => createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            background: 'transparent',

          },
        },
      },
      MUIDataTable: {
        styleOverrides: {
          paper: {
            backgroundColor: "#25282c",
            boxShadow: "none !important"
          }
        },
      },
      MuiTable: {
        styleOverrides: {

        root: {
          border: 'none'
        }
      }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          sortActive: {
            color: '#f2f2f2'
          }
        }
      },

      MuiButton: {
        styleOverrides: {
          root : {
            textTransform : "capitalize",
            fontSize : "16px",
            fontWeight: "bold"

          },

        }
      },
      MuiTableSortLabel: {
        styleOverrides : {
        root: {
          color: '#f2f2f2',
          fontSize: 30,
          background: 'transparent',
          "&:hover": {
            color: '#f2f2f2',
            fontSize: 30,
            background: 'transparent',
            '&& $icon': {
              opacity: 1,
              fontSize: 30,
              color: '#f2f2f2',
              background: 'transparent',
            },
          },
          "&$active": {
            color: '#f2f2f2',
            fontSize: 30,
            background: 'transparent',
            '&& $icon': {
              opacity: 1,
              fontSize: 30,
              background: 'transparent',
              color: '#f2f2f2'
            },
          },
        },
      },
      },

      MuiTableSortLabel: {
        styleOverrides :{
        icon: {
          background: 'transparent !important',
          fontSize: '40px !important',
          fill: '#f2f2f2 !important',
        }
      }
      },
      MuiPaginationItem: {
        styleOverrides :{
          root : {
            color : "#337ab7",
            fontSize : "16pt",
            '&.Mui-selected': {
              color: '#ffffff !important',
            },
          },
          icon : {
            background : 'transparent',
            fontSize: '40px !important',

          }
        }
      },
      MuiPagination: {
        styleOverrides : {
          root : {
            marginTop : "20px",

          }
        }
      },
      MUIDataTableBody: {
        styleOverrides: {

          emptyTitle: {
            fontSize: '24px',
            fontFamily: 'Roboto',
            fontWeight: 'bold'
          }
        }
      }
    }
  });

  getListData(page, query, sortOrder) {
    this.setState({
      sortOrder: sortOrder
    });
    fetch("/record_editor/report_list_data.json?page=" + page + "&query=" + query + "&sort_key=" + sortOrder.name + "&sort_direction=" + sortOrder.direction)
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          this.setState({
            record_reports: result.record_reports,
            total: result.total,
            sortOrder: sortOrder,
            is_plum_coulee: result.is_plum_coulee,
            searchTimer: undefined
          });
        }
      )
  }

  render() {
    console.log("RENDER")
    console.log(this.state.sortOrder)
    const record_reports_options = {
      filter: false,
      filterType: 'multiselect',
      search: false,
      print: false,
      download: false,
      viewColumns: false,
      selectableRows: "none",
      searchText: this.state.search_value,
      count: this.state.total,
      rowHover: false,
      responsive: 'vertical',
      sortOrder: this.state.sortOrder,
      rowsPerPage: 15,
      serverSide: true,
      onTableChange: (action, tableState) => {

        console.log(action, tableState);

        // a developer could react to change on an action basis or
        // examine the state as a whole and do whatever they want

        switch (action) {
          case 'changePage':
            this.getListData(tableState.page, this.state.search_value, this.state.sortOrder);
            break;
          case 'sort':
            var sortOrder = this.state.sortOrder;
            var existingColumns = this.state.record_reports_columns;

            for(var i = 0; i < tableState.columns.length; i++){
              var column = tableState.columns[i];

              for(var existingColumnIndex = 0; existingColumnIndex < existingColumns.length; existingColumnIndex++){
                var existing = existingColumns[existingColumnIndex];
                if(existing.name == column.name){
                  existing.options.sortOrder = tableState.sortOrder;
                  existingColumns[existingColumnIndex] = existing;
                }
              }

              if(tableState.sortOrder.direction != "none"){
                sortOrder.name = tableState.sortOrder.name;
                sortOrder.direction = tableState.sortOrder.direction;
                break;
              }
            }
            this.setState({
              record_reports_columns: existingColumns
            });
            console.log(sortOrder)
            this.getListData(tableState.page, this.state.search_value, sortOrder);
            break;
          default:
            console.log('action not handled.');
        }
      },
      // customSearch: (searchQuery, currentRow, columns) => {
      //   this.getListData(1);
      //   return true;
      // },
      setTableProps: () => {
        return {
          className: "re-better-reports-table"
        };
      },
      setCellHeaderProps: () => {
        return {
          className: "re-better-reports-table"
        };
      },
      setRowProps: (row) => {
        return {
          className: "re-report-rows",
        };
      },
      textLabels: {
        body: {
          noMatch: "No reports matching search",
          toolTip: ""
        }
      },
      customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels) => {
        return (
          <CustomPagination
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            changeRowsPerPage={changeRowsPerPage}
            changePage={changePage}
            textLabels={textLabels}
          />
        );
      }
    };

    return (
      <div className="main-content">
        <div id="record-editor-container" className="container-fluid">
          <div className="row-fluid">
            <div id="record-editor-header">
              <h1>Record Editor</h1>
            </div>
            <div id="record-editor-sub-header">
              Search completed BMHS records.
            </div>
            <div style={{outline: this.state.searching ? "5px auto -webkit-focus-ring-color" : ""}}
                 id="record-editor-search-container">
              <input id="record-search-box"
                     onFocus={this.searchFocusIn}
                     onBlur={this.searchFocusOut}
                     type="text" value={this.state.search_value}
                     onChange={this.handleChange}
                     placeholder="search by incident number, officer, badge, submission date..."/>
              <button id="record-editor-clear-search" onClick={this.handleClear}>Clear</button>
            </div>
            <ThemeProvider theme={this.getMuiTheme()}>
              <MUIDataTable
                data={this.state.record_reports}
                columns={this.state.record_reports_columns}
                options={record_reports_options}
              />
            </ThemeProvider>
          </div>
        </div>
      </div>
    );
  }
}

function Flag(props) {
  const flagged = props.flagged;
  if (flagged === 1) {
    return <WarningIcon
      style={{
        color: "#e6902a",
        background: 'transparent',
        fontSize: 30
      }}
    />
  } else {
    return (<div></div>);
  }
}

function ViewOrUnarchiveButton(props) {
  const archived = props.archived;
  if (!!archived) {
    return (<ArchiveReport
      report_id={props.report_id}
      archive={false}
      class_name={"record-editor-unarchive-report-button"}>
    </ArchiveReport>)
  } else {
    var report_link = 'record_editor/report/' + props.report_id;
    return (<Button
      href={report_link}
      className="record-editor-view-report-button">
      VIEW
    </Button>);
  }
}

//Input: report_id, archive, and class_name
function ArchiveReport(props) {
  return (
    <button
      onClick={() => {
        archiveReport(props.report_id.toString(), props.archive)
      }}
      className={props.class_name}>
      UNARCHIVE
    </button>
  )
}

class CustomPagination extends React.Component {
  constructor(props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handlePageChange = (_, newPage) => {
    this.props.changePage(newPage - 1);
  };

  render() {
    const {count, classes, textLabels, rowsPerPage, page} = this.props;
    const footerStyle = {
      display: 'flex',
      justifyContent: 'center',
      padding: '0px 24px 0px 24px'
    };

    return (
      <TableFooter>
        <TableRow>
          <TableCell className='re-updated-pagination' style={footerStyle} colSpan={1000}>
            <Pagination
              page={page + 1}
              count={count}
              siblingCount={2}
              boundaryCount={2}
              onChange={this.handlePageChange}
              showFirstButton={true}
              showLastButton={true}
            />
          </TableCell>
        </TableRow>
      </TableFooter>
    );
  }
}

export default Index
