import {ResponsivePie} from "@nivo/pie";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import React from "react";

function PieGraph(props) {
  return (
    <div className="graph-sub-container"
         style={{backgroundColor: props.viewing ? "#1a1c1f" : "transparent"}}
    >
      <div id="graph-title-text-container" className="graph-title-text-container">
        <div className="graph-title-text" style={{color: props.graph_colour}}>
          <p>{props.graph_title}</p>
        </div>
        <div className="graph-sub-title-text">
          <p>{props.graph_sub_title}</p>
        </div>
      </div>
      <ChartPie
        formatValue={props.formatValue}
        graph_data={props.graph_data}
        hover={props.hover}
        show_hover={props.show_hover}
        graph_colour={props.graph_colour}
        viewing={props.viewing}
        opacity_text={props.opacity_text}
      />


      <br/><br/>
      <div id="graph-legend-container" className="graph-legend-container">
        <div id="graph-legend-text" className="graph-legend-title-text" style={{color: props.graph_colour}}>
          <p>Legend</p>
        </div>

        <Grid id="graph-legend" container spacing={2}>
          {
            props.graph_full_data.map((item, index) => {
              return (
                <Tooltip
                  key={index}
                  title={`${item.label}: ${props.formatValue(item["value"], true)}`}
                  placement='bottom-end'
                >
                  <Grid key={index} item xs={3} sm={3}
                        className={item["show"] ? "graph-legend-item-container" : ""}
                        onClick={() => {
                          props.handleLegendClick(item["index"], "index", "find")
                        }}
                        onMouseEnter={() => {
                          props.handleLegendOnHover(item["index"])
                        }}
                        onMouseLeave={() => {
                          props.handleLegendOnHoverExit(item["index"])
                        }}
                        style={{
                          display: item["show"] || props.viewing ? "block" : "none",
                          opacity: item["show"] ? 1 : 0.2
                        }}
                  >
                    <div className="graph-legend-item" style={{color: props.graph_colour}}>
                      <span
                        className="graph-legend-icon"
                        style={{
                          backgroundColor: item["color"],
                          height: 20,
                          width: 20,
                          float: "left",
                          borderRadius: "50%",
                          margin: 5,
                          border: "1px solid #000000"
                        }}
                      />
                      <div className="graph-label" style={{color: props.graph_colour}}> {item["label"]} </div>
                    </div>

                  </Grid>
                </Tooltip>
              )
            })
          }

        </Grid>
      </div>
    </div>
  )
}

function ChartPie(props) {
  return (
    <div className="graph-chart-container">
      <canvas id="temp-canvas"/>
      <img id="temp-img"/>
      <ResponsivePie
        data={props.graph_data}
        margin={{top: 60, right: 60, bottom: 60, left: 60}}
        radialLabelsTextColor={d => props.hover ? (d.show_hover ? props.graph_colour : props.graph_colour.replace("1)", props.opacity_text)) : props.graph_colour}
        radialLabelsLinkColor={d => props.hover ? (d.show_hover ? props.graph_colour : props.graph_colour.replace("1)", props.opacity_text)) : props.graph_colour}
        borderColor={d => props.hover ? (d.show_hover ? "#000000" : "none") : "#000000"}
        borderWidth={1}
        radialLabelsLinkStrokeWidth={2}
        colors={d => props.hover ? (d.show_hover ? d.color : d.color.replace("1)", props.opacity_text)) : d.color}
        enableSlicesLabels={false}
        animate={false}
        radialLabelsSkipAngle={props.hover ? 0 : 0.01}
        radialLabel={function (e) {
          return props.formatValue(e.value, false, false)
        }}
        tooltipFormat={function (v) {
          return props.formatValue(v, true, false)
        }}
        theme={
          {
            labels: {
              text: {
                fontSize: 16,
                fontFamily: "Helvetica"
              }
            }
          }
        }
      />
    </div>
  )
}

export default PieGraph