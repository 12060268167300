import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

export default class OrganizationInformation extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {org_info, org_name, org_id} = this.props;
    return (
      <div className="main-content">
        <div id="record-editor-container" className="container-fluid">
          <div className="row-fluid">
            <div id="record-editor-header">
              <h1>{org_name}</h1>
            </div>
          </div>
        </div>
        <Container className="ge-org-display">
          {org_info.map((data) =>{
            return (
              <DisplayRow data={data}/>
            )
          })}
        </Container>
        <br/>
        <LogExport
          id={org_id}
          title="AUDIT LOG EXPORT"
          route="ge_audit_log_export"
        />

        <br/>
        <LogExport
          id={org_id}
          title="ACTIVITY LOG EXPORT"
          route="ge_activity_log_export"
        />

        <br/>
        <LogExport
          id={org_id}
          title="STATUS LOG EXPORT"
          route="ge_status_log_export"
        />
      </div>
    )
  }
}

function DisplayRow (props) {
  const {data} = props
  if (data.type === 'org') {
    return (
      <Row className="ge-org-display-row">
        <Col className="ge-org-display-title" lg={6}>
          {data.label}:
        </Col>
        <Col className="ge-org-display-data" lg={6}>
          {
            data.value.map((d) => {
                return (
                  <div>
                    <a href={`/golden_eye/organization_information/${d.id}`}>{d.name}</a>
                  </div>

                )
              }
            )
          }
        </Col>
      </Row>
    )
  }
  return (
    <Row className="ge-org-display-row">
      <Col className="ge-org-display-title" lg={6}>
        {data.label}:
      </Col>
      <Col className="ge-org-display-data" lg={6}>
        {data.value}
      </Col>
    </Row>
  )
}

class LogExport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      start_date: new Date((new Date()).setDate((new Date()).getDate() - 7)),
      end_date: new Date(),
    }
  }

  handleDateChange = (date, date_type) => {
    this.setState({
      [date_type]: date
    });
  };

  handleLogDownload = () => {
    const {start_date, end_date} = this.state;
    const {id, route} = this.props;
    window.location = `/golden_eye/${route}?start_date=${start_date}&end_date=${end_date}&id=${id}`
  };

  render() {
    const {title} = this.props;
    return (
      <Container className="ge-org-display">
        <Row>
          <Col>
            <div>
              <p>{title}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <div>
              <p>Start Date</p>
            </div>
            <div className="graph-update-fields-box-date">
              <DatePicker
                onChange={(value) => {
                  this.handleDateChange(value, "start_date")
                }}
                selected={this.state.start_date}
                dateFormat="yyyy-MM-dd"
                className="graph-control-button-date"
                placeholderText="YYYY-MM-DD"/>
            </div>
          </Col>
          <Col lg={6}>
            <div>
              <p>End Date</p>
            </div>
            <div className="graph-update-fields-box-date">
              <DatePicker
                onChange={(value) => {
                  this.handleDateChange(value, "start_date")
                }}
                selected={this.state.end_date}
                dateFormat="yyyy-MM-dd"
                className="graph-control-button-date"
                placeholderText="YYYY-MM-DD"/>
            </div>
          </Col>
        </Row>
        <Row>
          <br/>
        </Row>
        <Row>
          <Col lg={4}/>
          <Col lg={4}>
            <div className="graph-control-button-active"
                 onClick={() => {
                   this.handleLogDownload()
                 }}
            >
              Download
            </div>
          </Col>
          <Col lg={4}/>
        </Row>
        <Row>
          <br/>
        </Row>
      </Container>
    )
  }
}