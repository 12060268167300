import React from 'react'
import {Modal} from "react-bootstrap"
import CircularProgress from "@mui/material/CircularProgress";

function CloseMatchModal(props) {
  return (
    <div>
      <Modal id="close_match_modal"
             backdropClassName="report_view_edit_modal_backdrop"
             show={props.show_close_match_modal} backdrop={true} keyboard={false} animation={false}
             onHide={props.handleHideCloseMatchModal} dialogClassName={"re-modal-content"}>
        <div id="close-match-header-container">
          <div id="close-match-spinner" className={`spinner-container`}>
            <CircularProgress
              size={20}
              thickness={5}
              color="inherit"
              className={props.close_match_spinner ? '' : 'hidden'}
              classes={{
                root: 'editor-spinner-root',
                svg: 'view_report_spinner'
              }}
            />
          </div>
          <button className="x-button" onClick={props.handleHideCloseMatchModal}>X</button>
          <div id="close-match-results-header">
            <h2>Search Results</h2>
          </div>
        </div>

        <div id="search-results" className="search-results">
          <p id="search-result-explainer" className="search-results-head">{props.close_match_explainer_text}</p>
          <div id="results-array" className="results-array">
            {
              props.close_match_list.slice(0, 20).map((item, index) => {
                return (
                  <CloseMatch
                    key={index}
                    index={index}
                    report={item}
                    handleClickCloseMatch={props.handleClickCloseMatch}
                    key_data={props.key_data}
                  />
                )
              })
            }
          </div>

          <div className="result-bottom-nav">
            <div className="result-btn-div">
              <div className="result-new" tabIndex="0" id="close-match-new-person-button"
                   onClick={() => {
                     props.handleClickCloseMatch(null)
                   }}> New Person
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

function CloseMatch(props) {
  var current_report = {};
  if (props.report !== null) {
    if (props.report['officerSafetyEncryptedData'] || props.report['lastRecordEncryptedData']) {
      try {
        var privateKey = forge.pki.privateKeyFromPem(props.key_data.organizationPrivateKey);
        if (props.report['officerSafetyEncryptedData']) {
          var decrypted = decryptReportDataWithKeys(props.report, privateKey, 'officerSafetyEncryptionKey', 'officerSafetyEncryptedData');
          current_report = JSON.parse(decrypted);
          if (!current_report.fullSafetyData) {
            decrypted = decryptReportDataWithKeys(props.report, privateKey, 'lastRecordEncryptionKey', 'lastRecordEncryptedData');
            current_report = JSON.parse(decrypted);
          }
        } else {
          decrypted = decryptReportDataWithKeys(props.report, privateKey, 'lastRecordEncryptionKey', 'lastRecordEncryptedData');
          current_report = JSON.parse(decrypted);
        }
        var decrypted_old = decryptReportDataWithKeys(props.report, privateKey, 'lastRecordEncryptionKey', 'lastRecordEncryptedData');
        var current_report_old = JSON.parse(decrypted_old);
      } catch (e) {
        // console.log("Error");
        // console.log(e);
      }
      var full_name = [current_report['firstName'], current_report['middleName'], current_report['lastName']].join(' ');
      if (current_report_old && full_name.trim().length === 0) {
        full_name = [current_report_old['firstName'], current_report_old['middleName'], current_report_old['lastName']].join(' ');
      }
      return (
        <div key={props.index} id="result" className="result-block">
          <div className="result-view-btn" tabIndex="0" onClick={() => {
            props.handleClickCloseMatch(props.report.caseID)
          }}>Select
          </div>
          <table id="close-match-table">
            <tbody>
            <tr className="result-block-identifiers">
              <td className="result-block-identifiers-p">Name:</td>
              <td
                className="result-block-data-p">{full_name}</td>
            </tr>
            <tr className="result-block-identifiers">
              <td className="result-block-identifiers-p">Aliases:</td>
              <td className="result-block-data-p">{current_report['aliases']}</td>
            </tr>
            <tr className="result-block-identifiers">
              <td className="result-block-identifiers-p">D.O.B:</td>
              <td
                className="result-block-data-p">{[props.report['birthYear'], props.report['birthMonth'], props.report['birthDay']].join('-')}</td>
            </tr>
            <tr className="result-block-identifiers">
              <td id="close-match-last-row" className="result-block-identifiers-p">Address:</td>
              <td className="result-block-data-p">{current_report['address']}</td>
            </tr>
            </tbody>
          </table>
        </div>
      )
    } else {
      return (<div key={props.index}></div>)
    }
  } else {
    return (<div key={props.index}></div>)
  }

}

export default CloseMatchModal
